import React from "react";
import { Card, Typography, Box, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { WidgetsType } from "../../Models/Dashboard";

const WidgetContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  background: "#f9fafb",
  border: '1.5px solid darkgrey',
  boxShadow: '5px 0 5px -3px #888'
}));

const IconContainer = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1.5px solid #16314c",
  color: theme.palette.primary.contrastText,
  borderRadius: "70%",

  width: 80,
  height: 80,
  border: "0.1px solid secondary",
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
}));

interface Props {
  widgetObj: WidgetsType;
}

const Widget = ({ widgetObj }: Props) => {
  return (
    <WidgetContainer>
      <IconContainer>{widgetObj.icon}</IconContainer>
      <InfoContainer textAlign={"center"}>
        <Typography variant="body2" className="orderStatsTitle">
          <b>{widgetObj.title}</b>
        </Typography>
        <Typography variant="h6" sx={{ color: "text.disabled" }}>
          {widgetObj.current}
        </Typography>
        <Box display="flex" alignItems="center" paddingLeft={11}>
          {widgetObj.percentage >= 0 ? (
            <ArrowUpwardIcon style={{ color: "green" }} />
          ) : (
            <ArrowDownwardIcon style={{ color: "red" }} />
          )}
          <Tooltip
            title={widgetObj.percentage >= 0 ? "Increased" : "Decreased"}
          >
            <Typography
              variant="body2"
              style={{
                color: widgetObj.percentage >= 0 ? "green" : "red",
                marginLeft: 4,
              }}
            >
              {Math.abs(widgetObj.percentage)}%
            </Typography>
          </Tooltip>
        </Box>
      </InfoContainer>
    </WidgetContainer>
  );
};

export default Widget;
