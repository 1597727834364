import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_SETUP_GUIDE_STEPS } from "../../Utils/Constants";
import { AxiosError, AxiosResponse } from "axios";
import AxiosInstance from "../../Network/AxiosInstance";

export const setupGuideStepsApi = createAsyncThunk("setupGuide/steps", async (_, thunkAPI) => {
  try {
    let url: string = `${GET_SETUP_GUIDE_STEPS}`;

    const response: AxiosResponse = await AxiosInstance.get(url);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error
    });
  }
});

const initialState: any = {
  setupGuideSteps: {},
  isSetupApiDone: false
};

const setupGuideSlice: any = createSlice({
  name: "setUpGuide",
  initialState,
  reducers: {
    // updateAuth: (state, { payload }) => {
    //   // console.log(payload);
    //   state.isAuthenticated = payload;
    // },
    // toggleMenu: (state, action) => {
    //   state.isMenuExpanded = !state.isMenuExpanded;
    // },
    // toggleMenuOff: (state, action) => {
    //   state.isMenuExpanded = false;
    // },
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder
      .addCase(setupGuideStepsApi.pending, (state, action: PayloadAction<any>) => {
        // state.status = 'loading' console.log("shippingMethodListApi.pending", action)
      })
      .addCase(setupGuideStepsApi.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 200 && action.payload.success) {
            state.isSetupApiDone = true;
        }
        // console.log("shippingMethodListApi.fulfilled", action)
        const res = action.payload;
        if (res && res.response !== null && res.response !== undefined) {
          state.setupGuideSteps = res.response;
          state.isSetupApiDone = true;
        }
      })
      .addCase(setupGuideStepsApi.rejected, (state, action) => {
        // console.log("shippingMethodListApi.rejected", action)
      });
  },
});

// export const { updateAuth, toggleMenu, toggleMenuOff } = setupGuideSlice.actions;

export default setupGuideSlice.reducer;
