import React from "react";
import Typography from "@mui/material/Typography";
import { Alert, Stack } from "@mui/material";

const Reports: React.FC = () => {
  return (
    <div className="">
      <div className="mainDiv">
        <div className="titleContainer">
          <div className="left">
            <div className="pageTitle">
              <Typography variant="h5">Reports</Typography>
            </div>
          </div>
          <div className="right"></div>
        </div>

        <div>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="info" variant="outlined">
              Coming Soon...
            </Alert>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Reports;
