import React from "react";
import {SpeedDial, SpeedDialIcon, SpeedDialAction} from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';

function QuickAccess(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const reportIssue = () => {
    window.open(process.env.REACT_APP_ZENDESK_BASE_URL + 'hc/en-us/requests/new', '_blank');
  };

  const actions = [
    { icon: <BugReportIcon />, name: 'Report an Issue', method: reportIssue },
  ];

  return (
    <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 25, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
    >
        {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.method}
            />
        ))}
    </SpeedDial>
  );
}

export default QuickAccess;
