import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { Box, Grid, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import PrivacyTipRoundedIcon from '@mui/icons-material/PrivacyTipRounded';
import { useEffect, useState } from 'react';
import { LinearProgress, linearProgressClasses } from "@mui/material"
import { styled } from '@mui/system';
import { useAppSelector } from '../../Redux/Hooks/hooks';
import { GUIDED_STEPS_LIST } from '../../Utils/Utils';

interface Props {
    id: string;
    open: boolean;
    anchorEl: any;
    handleClose: any
}
const SetupGuidePopover = ({ id, open, anchorEl, handleClose }: Props) => {
    const settingInfo = useAppSelector((state: any) => state.reducer.setUpGuide);
    // console.log("setupGuideSteps", settingInfo.setupGuideSteps)

    const [percetage, setPercetage] = useState(0.00);
    const [totalStepsCount] = useState(GUIDED_STEPS_LIST.length);
    const [completedStepsCount, setCompletedStepsCount] = useState(0);

    useEffect(() => {
        if (typeof settingInfo.setupGuideSteps !== 'undefined') {
            var completedStepsCount = 0;
            if (Object.keys(settingInfo.setupGuideSteps).length > 0) {
                GUIDED_STEPS_LIST.forEach((step: any) => {
                    if (settingInfo.setupGuideSteps[step] === true) {
                        completedStepsCount = (completedStepsCount + 1);
                    }
                });
            }

            setCompletedStepsCount(completedStepsCount);
            setPercetage((completedStepsCount * 100 / totalStepsCount))
        }
        // eslint-disable-next-line
    }, [settingInfo])

    const handleShowsetupGuidePopover = () => {
        handleClose()
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 25,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor:
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
        },
    }));


    function LinearProgressWithLabel(props: any) {
        return (
            <Box className="connect-health-progress">
                <Box className="width100">
                    <BorderLinearProgress variant="determinate" {...props} />
                </Box>
                <Box className="health-progress-value-txt">
                    <Typography variant="caption" component="div" align='center' color={'white'}>
                        {`${Math.round(props.value)}% Completed`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className='popover'>
                <div style={{ padding: '3px' }} className='popoverHeader'>
                    <Grid container item>
                        <Grid>
                            <PrivacyTipRoundedIcon color="error" />
                        </Grid>
                        <Grid>
                            <Typography variant="h6" style={{padding: '3px'}}>
                                <span>Setup Guide Steps</span>
                            </Typography>
                        </Grid>
                        <Grid>
                            <IconButton
                                aria-label="Close"
                                className="popovercloseButton"
                                color="error"
                                onClick={handleShowsetupGuidePopover}
                            >
                                <GridCloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>              
                </div>
                <div className='popoverBody'>
                    <div style={{ padding: '10px' }}>
                        <LinearProgressWithLabel value={Number(percetage) || 0} />
                        <span className='badge badgeLight stepsCompleted'> Steps completed : {completedStepsCount + '/' + totalStepsCount}</span>
                    </div>
                </div>
            </div>

        </Popover>
    );
};

export default SetupGuidePopover;
