import { experimental_extendTheme as extendTheme } from "@mui/material";

const darkTheme = extendTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#16314c",
    },
    secondary: {
      main: "#f49044",
    },
    backgroundcolor: "#eaecf8",
    colorWhite: "#ffffff",
    colorBlack: "#000000",
    colorBlue: "#588bca",
    background: {
      default: "hsl(230, 17%, 14%)",
    },
  },
});

export default darkTheme;
