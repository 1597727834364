import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse, AxiosError} from "axios";
import AxiosInstance from "../../Network/AxiosInstance";
import {GET_SHIPPING_METHOD, GET_SHIPPING_CARRIER_METHOD, GET_SHIPPING_METHOD_STORE_MAPPINGS} from "../../Utils/Constants";
import {returnObjectToQueryParams} from "../../Utils/Utils";

export const shippingMethodListApi = createAsyncThunk("shippingMethod/list", async(payload : any, thunkAPI) => {
    try {
        var pageSize = (typeof payload.pageSize !== 'undefined') ? payload.pageSize : '';
        var page = (typeof payload.page !== 'undefined') ? payload.page : 0;

        let url : string = `${GET_SHIPPING_METHOD}?size=${pageSize}&page=${page + 1}&${returnObjectToQueryParams(payload.filter)}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const createShippingMethodApi = createAsyncThunk("shippingMethod/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.post(GET_SHIPPING_METHOD, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});
export const updateShippingMethodApi = createAsyncThunk("shippingMethod/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.put(GET_SHIPPING_METHOD + "/" + payload.id, {name: payload.name});
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const shippingCarrierMethodListApi = createAsyncThunk("shippingMethod/list", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_SHIPPING_CARRIER_METHOD}?`;
        if (payload.shippingMethodId) {
            url += `ship_method=${payload.shippingMethodId}&`;
        }

        var pageSize = (typeof payload.pageSize !== 'undefined') ? payload.pageSize : '';
        var page = (typeof payload.page !== 'undefined') ? payload.page : 0;

        url += `size=${pageSize}&page=${page + 1}&${returnObjectToQueryParams(payload.filter)}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const createShippingCarrierMethodApi = createAsyncThunk("shippingMethod/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.post(GET_SHIPPING_CARRIER_METHOD, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});
export const updateShippingCarrierMethodApi = createAsyncThunk("shippingMethod/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.put(GET_SHIPPING_CARRIER_METHOD + "/" + payload.id, payload.data);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const shippingMethodStoreMappingListApi = createAsyncThunk("shippingMethodStoreMapping/list", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_SHIPPING_METHOD_STORE_MAPPINGS}?size=${payload.pagination.pageSize}&page=${payload.pagination.page + 1}`;
        url += `&${returnObjectToQueryParams(payload.filter)}`;

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const createShippingMethodStoreMappingApi = createAsyncThunk("shippingMethodStoreMapping/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.post(GET_SHIPPING_METHOD_STORE_MAPPINGS, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const updateShippingMethodStoreMappingApi = createAsyncThunk("shippingMethodStoreMapping/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.put(GET_SHIPPING_METHOD_STORE_MAPPINGS + "/" + payload.id, payload.data);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

const initialState = {};

const orderSlice = createSlice({
    name: "shippingMethod",
    initialState,
    reducers: {},

    extraReducers(builder) {
        // omit posts loading reducers
        builder.addCase(shippingMethodListApi.pending, (state, action : PayloadAction < any >) => {
            // state.status = 'loading' console.log("shippingMethodListApi.pending", action)
        }).addCase(shippingMethodListApi.fulfilled, (state, action : PayloadAction < any >) => {
            if (action.payload.code === 200 && action.payload.success) {}
            // console.log("shippingMethodListApi.fulfilled", action)
        }).addCase(shippingMethodListApi.rejected, (state, action) => {
            // console.log("shippingMethodListApi.rejected", action)
        });
    }
});

export default orderSlice.reducer;
