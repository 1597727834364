import React, { useEffect, useState, lazy, Suspense } from "react";
import { Box, Grid, Paper } from "@mui/material";
import Widget from "./Widget";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import {
  dashboardResType,
  salesByChannelType,
  WidgetsType,
} from "../../Models/Dashboard";
const ChartComponent = lazy(
  () => import("../ResusableComponents/ChartComponent")
);

interface Props {
  periodType: string;
  salesCount: dashboardResType[];
  salesByDate: any;
  salesByChannel: salesByChannelType[];
}

export default function DashboardGrid({
  periodType,
  salesCount,
  salesByDate,
  salesByChannel,
}: Props) {
  
  const [widgetsList, setWidgetsList] = useState<WidgetsType[]>([
    {
      id: "received_orders",
      title: "Received Orders",
      current: 0,
      previous: 0,
      percentage: 0,
      icon: <ShoppingCartIcon color="info" className="orderStatsIcons" />,
    },
    {
      id: "open_orders",
      title: "Open Orders",
      current: 0,
      previous: 0,
      percentage: 0,
      icon: <ShoppingBagIcon color="warning" className="orderStatsIcons" />,
    },
    {
      id: "shipped_orders",
      title: "Shipped Orders",
      current: 0,
      previous: 0,
      percentage: 0,
      icon: (
        <LocalShippingIcon
          style={{ color: "green" }}
          className="orderStatsIcons"
        />
      ),
    },
    {
      id: "cancelled_orders",
      title: "Cancelled Orders",
      current: 0,
      previous: 0,
      percentage: 0,
      icon: (
        <RemoveShoppingCartIcon color="error" className="orderStatsIcons" />
      ),
    },
  ]);

  useEffect(() => {
    if (salesCount.length > 0) {
      // if the result from the API is not empty then process
      let updatedWidgets = widgetsList.map((localWidget: WidgetsType) => {
        let filteredResWidget = salesCount.filter(
          (resWidget: dashboardResType) => {
            return resWidget.xLabels === localWidget.title;
          }
        );

        return {
          ...localWidget,
          current: filteredResWidget[0]["Current"],
          previous: filteredResWidget[0]["Previous"],
          percentage: filteredResWidget[0]["Percentage"],
        };
      });

      // console.log("Widget", updatedWidgets); // logging the updated widgets
      setWidgetsList(updatedWidgets);
    }
    // eslint-disable-next-line
  }, [salesCount]);

  const salesRevenueChartData: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Sales Revenue",
    },
    subtitle: {
      text: periodType,
    },
    xAxis: {
      categories: salesByDate.Categories,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Revenue in Dollar($)",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b> ${point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Previous",
        type: "column",
        data: salesByDate.Previous,
      },
      {
        name: "Current",
        type: "column",
        data: salesByDate.Current,
      },
    ],
  };

  const salesByChannelChartData: any = {
    chart: {
        type: 'pie'
    },
    title: {
        text: 'Sales By Channel'
    },
    tooltip: {
        valuePrefix: '$'
    },
    subtitle: {
        text: periodType
    },
    plotOptions: {
        series: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: [{
                enabled: true,
                distance: 20
            }, {
                enabled: true,
                distance: -40,
                format: '{point.percentage:.1f}%',
                style: {
                    fontSize: '1.2em',
                    textOutline: 'none',
                    opacity: 0.7
                },
                filter: {
                    operator: '>',
                    property: 'percentage',
                    value: 10
                }
            }]
        }
    },
    series: [
        {
            name: 'Price',
            colorByPoint: true,
            data: salesByChannel
        }
    ]
}

  return (
    <Box sx={{ flexGrow: 1, mt: 1 }}>
      <Grid container spacing={2}>
        {/* First Row: 4 Widgets */}

        {widgetsList.map((localWidget) => (
          <Grid item xs={12} sm={6} md={3} key={localWidget.id}>
            <Paper>
              <Widget widgetObj={localWidget} />
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12} md={6}>
          <Paper>
            <Suspense fallback={<div className="">Loading...</div>}>
              <ChartComponent chartData={salesRevenueChartData} />
            </Suspense>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <Suspense fallback={<div className="">Loading...</div>}>
              <ChartComponent chartData={salesByChannelChartData} />
            </Suspense>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
