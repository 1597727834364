import React, { lazy } from "react";
// import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Roles from "../Configurations/Roles";
import ProtectedRoutes from "./ProtectedRoutes";
import SuspenseContainer from "./SuspenseContainer";
import { useAppSelector } from "../Redux/Hooks/hooks";
// import SetupGuide from "../Pages/SetupGuide";
import BlankPage from "../Pages/BlankPage";
import BCRedirectAndInstructionsGuide from "../Pages/BCRedirectAndInstructionsGuide";
import Dashboard from "../Pages/Dashboard";
import Reports from "../Pages/Reports";
import AddOns from "../Pages/AddOns";

// Lazy-loaded components
const Welcome = lazy(() => import("../Pages/Welcome"));
//const Dashboard = lazy(() => import("../Pages/Dashboard"));
const NotFound = lazy(() => import("../Pages/NotFound"));

const ListOrder = lazy(() => import("../Pages/Order/List"));
const AddOrder = lazy(() => import("../Pages/Order/Add"));
const EditOrder = lazy(() => import("../Pages/Order/Edit"));
const ViewOrder = lazy(() => import("../Pages/Order/View"));

const Stores   = lazy(() => import("../Pages/Stores/List"));
const Settings = lazy(() => import("../Pages/Settings/List"));
const Register = lazy(() => import("../Pages/Register"));

const MainRoutes = React.memo(() => {
  const {isSetupApiDone} = useAppSelector((state) => state.reducer.setUpGuide);

  return (
    <React.Fragment>
      <Routes>

        <Route
          path="/register"
          element={
            <SuspenseContainer>
              <Register />
            </SuspenseContainer>
          }
        />

        <Route
          path="/bc_redirect"
          element={
            <SuspenseContainer>
              <BCRedirectAndInstructionsGuide />
            </SuspenseContainer>
          }
        />

        <Route
          path="/bc_instruction_guide"
          element={
            <SuspenseContainer>
              <BCRedirectAndInstructionsGuide />
            </SuspenseContainer>
          }
        />

        {/* below condition and route is added, because of not found route is displayed after login for fraction of second */}
        {!isSetupApiDone && (
        	<>
              <Route
                path="/register"
                element={
                  <SuspenseContainer>
                    <Register />
                  </SuspenseContainer>
                }
              />
      
              <Route
                path="/bc_redirect"
                element={
                  <SuspenseContainer>
                    <BCRedirectAndInstructionsGuide />
                  </SuspenseContainer>
                }
              />
      
              <Route
                path="/bc_instruction_guide"
                element={
                  <SuspenseContainer>
                    <BCRedirectAndInstructionsGuide />
                  </SuspenseContainer>
                }
              />

	          <Route
	            path="*"
	            element={
	              <SuspenseContainer>
	                <ProtectedRoutes roles={[]} component={BlankPage} />
	              </SuspenseContainer>
	            }
	          />
          </>
        )}

        {isSetupApiDone && (
          <>
            <Route
              path="/"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={ListOrder} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/dashboard"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={Dashboard} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/welcome"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={Welcome} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/orders/add"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={AddOrder} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/orders/edit/:id"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={EditOrder} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/orders/view/:id"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={ViewOrder} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/orders"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={ListOrder} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/stores"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={Stores} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/settings"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={Settings} />
                </SuspenseContainer>
              }
            />
            <Route
              path="/reports"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={Reports} />
                </SuspenseContainer>
              }
            />

            <Route
              path="/addons"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={AddOns} />
                </SuspenseContainer>
              }
            />

            {/* <Route
              path="/setupGuide"
              element={
                <SuspenseContainer>
                  <ProtectedRoutes roles={[]} component={SetupGuide} />
                </SuspenseContainer>
              }
            /> */}
          </>
        )}

        {/* admin only -------------------------*/}
        <Route
          path="/"
          element={
            <ProtectedRoutes roles={[Roles.Admin]} component={ListOrder} />
          }
        >
          {/* <Route path="/Accounts/Users" element={<Users />} />
        <Route path="/Accounts/Payment" element={<Payment />} />
        <Route path="/MinimumBalance" element={<MinimumBalanceSetting />} /> */}
        </Route>

        {/* super-admin only --------------------*/}
        <Route
          path="/"
          element={
            <ProtectedRoutes roles={[Roles.SuperAdmin]} component={ListOrder} />
          }
        >
          {/* <Route path="/Accounts/Clients" element={<SuperAdminClients />} /> */}
        </Route>

        {/** Not Found route */}
        <Route
          path="*"
          element={
            <SuspenseContainer>
              <NotFound />
            </SuspenseContainer>
          }
        />
      </Routes>
    </React.Fragment>
  );
});

export default MainRoutes;
