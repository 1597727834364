// import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Roles from "../Configurations/Roles";
import StoreIcon from '@mui/icons-material/Store';
import SettingsIcon from '@mui/icons-material/Settings';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import DescriptionIcon from '@mui/icons-material/Description';
// import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

export interface ChilNavigationItem {
    title : string;
    to : string;
    role : number[];
    icon?: JSX.Element;
}
export interface NavigationItem {
    icon : JSX.Element;
    title : string;
    id : string;
    role : number[];
    to?: string;
    items?: ChilNavigationItem[];
}

export interface NavigationItems {
    sidebar : NavigationItem[];
    footer : NavigationItem[];
}

const navigationItems : NavigationItems = {
    sidebar: [
        // {
        //     icon: <DescriptionIcon fontSize="medium"/>,
        //     title: "Setup Guide",
        //     id: "setupGuide",
        //     role: [
        //         Roles.Admin, Roles.Manager
        //     ],
        //     to: "/setupGuide"
        // },
        // {
        //     icon: <DashboardIcon fontSize="medium"/>,
        //     title: "Dashboard",
        //     id: "dashboard",
        //     role: [
        //         Roles.Admin, Roles.Manager
        //     ],
        //     to: "/dashboard"
        // }, 
        {
            icon: <ShoppingCartIcon fontSize="medium"/>,
            title: "Orders",
            id: "orders",
            role: [
                Roles.Admin, Roles.Manager
            ],
            to: "/orders"
        }, {
            icon: <StoreIcon fontSize="medium"/>,
            title: "Stores",
            id: "stores",
            role: [
                Roles.Admin, Roles.Manager
            ],
            to: "/stores"
        }, 
        // {
        //     icon: <BarChartIcon fontSize="medium"/>,
        //     title: "Reports",
        //     id: "reports",
        //     role: [
        //         Roles.Admin, Roles.Manager
        //     ],
        //     to: "/reports"
        // }, 
        // {
        //     icon: <ElectricalServicesIcon fontSize="medium"/>,
        //     title: "Add-Ons",
        //     id: "addons",
        //     role: [
        //         Roles.Admin, Roles.Manager
        //     ],
        //     to: "/addons"
        // }
    ],
    footer: [
        {
            icon: <SettingsIcon fontSize="medium"/>,
            title: "Settings",
            id: "settings",
            role: [
                Roles.Admin, Roles.Manager
            ],
            to: "/settings"
        }
    ]
};

export default navigationItems;
