import React, { Suspense, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const SuspenseContainer: React.FC<Props> = ({ children }) => (
  <Suspense
    fallback={
      <div className="">
        <div className="mainDiv">Loading...</div>
      </div>
    }
  >
    {children}
  </Suspense>
);

export default SuspenseContainer;
