import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Assets/SWLoginLogo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import clsx from "clsx";
import swpFavIcon from "../Assets/swpIcon.png";
import { toggleMenu } from "../Redux/Slice/settingSlice";
import navigationItems from "../Utils/navigationItems";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks/hooks";
import SetupGuidePopover from "../Components/SetupGuide/SetupGuidePopover";

interface SidebarItem {
  role: number[]; // Assuming role is an array of numbers
  items?: SidebarItemChild[] | undefined;
}

interface SidebarItemChild {
  role: number[];
}

const Sidebar = () => {
  const location = useLocation();
  const navigation = useNavigate();

  const settingInfo = useAppSelector((state: any) => state.reducer.setUpGuide);
  const userInfo = useAppSelector((state: any) => state.reducer.userData);
  // userInfo.user_role_id ===> 1 =	SuperAdmin,  2 = Admin,  3 = Manager

  const filteredNavigationItems: SidebarItem[] = navigationItems.sidebar
    .filter((item: SidebarItem) => {
      return item.role.includes(userInfo.user_role_id);
    })
    .map((item: SidebarItem) => {
      const filteredItems: SidebarItemChild[] | undefined = item?.items?.filter(
        (child: SidebarItemChild) => child.role.includes(userInfo.user_role_id)
      );
      return { ...item, items: filteredItems };
    });

  const dispatch = useAppDispatch();
  const toggleSidebar = () => {
    dispatch(toggleMenu({}));
  };

  //   const switchThemeMode = () => {
  //     dispatch(toggleTheme());
  //   };

  const handleClick = (id: string, to: string) => {
    if (id === current) {
      setCurrent("");
    } else {
      setCurrent(id);
    }

    if (to !== undefined) {
      navigation(to);
    }
  };

  useEffect(() => {
    const path = location.pathname;

    // if (path === "/ConnectorSetup") {
    //   // toggle isMenuExpanded to false if path is store setup for more space
    //   dispatch(toggleMenuOff());
    // }
    // Iterate through navigationItems to find the matching item
    // and set its ID as the current state
    navigationItems.sidebar.forEach((item) => {
      setCurrentPage(item, path);
    });

    navigationItems.footer.forEach((item) => {
    	setCurrentPage(item, path);
    });
    // eslint-disable-next-line
  }, [location]);

  const setCurrentPage = (item: any, path: string) => {
      if (item.to === path) {
        setCurrent(item.id);
        if (item.items === undefined) {
          if (item.to === path) {
            setSubListCurrent(item.title);
          }
        }
      } else if (item.items) {
        item.items.forEach((child: any) => {
          if (child.to === path) {
            setCurrent(item.id);
            setSubListCurrent(child.title);
            // console.log("path",path)
            // if(path === "/ConnectorSetup"){// toggle isMenuExpanded to false if path is store setup for more space
            //   dispatch(toggleMenuOff())
            // }
          }
        });
      }
  };

  const [current, setCurrent] = useState("");
  const [subListCurrent, setSubListCurrent] = useState("");

  const expanded = useAppSelector(
    (state) => state.reducer.setting.isMenuExpanded
  );

  const changeCurrentSub = (title: string) => {
    setSubListCurrent(title);
  };

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = 'simple-popover-setupGuide';

  useEffect(() => {
    if (typeof settingInfo.setupGuideSteps !== 'undefined') {
      if (expanded || Object.keys(settingInfo.setupGuideSteps).length <= 0 || Object.values(settingInfo.setupGuideSteps).includes(false)) {
        setAnchorEl(anchorRef.current);
      } else {
        setAnchorEl(null);
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleClickPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`sidebar ${expanded && "expanded"}`}>
      <header className="txtCenterAlign">
        <img
          src={expanded ? Logo : swpFavIcon}
          className={expanded ? clsx("logo", "cursorPointer") : clsx("minLogo")}
          onClick={() => navigation("/orders")}
          alt=""
        />
        <IconButton
          size="large"
          edge="start"
          className="sidebarIcon"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => toggleSidebar()}
        >
          {expanded ? (
            <KeyboardArrowLeftIcon className="" />
          ) : (
            <KeyboardArrowRightIcon className="" />
          )}
        </IconButton>
      </header>
      {/* pass expanded or not from below and above open close icon and logo */}
      <div className={clsx("left-menu", "width100", "paddingTop20")}>
        {expanded &&
          filteredNavigationItems.map((item: any) => (
            <div key={item.id} className="left-listing">
              <ListItem
                button
                onClick={() => handleClick(item.id, item.to!)}
                className={current === item.id ? "active" : ""}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                {expanded  && (
                  <>
                    {item.id === 'setupGuide' ?
                      (
                        <ListItemText
                          primary={item.title}
                          aria-describedby={id}
                          onClick={handleClickPopover}
                          ref={anchorRef} 
                        />
                      ) :
                      (<ListItemText primary={item.title} className="" />)}
                  </>
                )}

                {expanded && item.items !== undefined ? (
                  current === item.id ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItem>
              {/* <Collapse in={current === item.id} timeout="auto" unmountOnExit> */}
              <Collapse
                in={current === item.id && expanded}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.items?.map((child: any, key: any) => (
                    <SingleLevel
                      key={key}
                      item={child}
                      expanded={expanded}
                      changeCurrentSub={changeCurrentSub}
                      className={
                        subListCurrent === child.title ? "activeSubList" : ""
                      }
                    />
                  ))}
                </List>
              </Collapse>
            </div>
          ))}

        {!expanded &&
          filteredNavigationItems.map((item: any) => (
            <div key={item.id}>
              {item.items === undefined && (
                <IconsOnly
                  key={item.id}
                  item={item}
                  expanded={expanded}
                  changeCurrentSub={changeCurrentSub}
                  current={current}
                  className={
                    subListCurrent === item.title
                      ? "activeIconOnly"
                      : "iconOnlyPadding"
                  }
                />
              )}
              {item.items?.map((child: any, key: any) => (
                <IconsOnly
                  key={key}
                  item={child}
                  expanded={expanded}
                  changeCurrentSub={changeCurrentSub}
                  current={current}
                  className={
                    subListCurrent === child.title
                      ? "activeIconOnly"
                      : "iconOnlyPadding"
                  }
                />
              ))}
            </div>
          ))}
      </div>

      { <div className={clsx("left-menu", "width100") + ' sidebarBottom'} >

        {expanded &&
          navigationItems.footer.map((item: any) => (
            <div key={item.id} className="left-listing">
              <ListItem
                button
                onClick={() => handleClick(item.id, item.to!)}
                className={current === item.id ? "active" : " " + current + ' <=> ' + item.id}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}                
                {expanded  && (<ListItemText primary={item.title} className="" />)} 

                {expanded && item.items !== undefined ? (
                  current === item.id ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItem>
              {/* <Collapse in={current === item.id} timeout="auto" unmountOnExit> */}
              <Collapse
                in={current === item.id && expanded}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.items?.map((child: any, key: any) => (
                    <SingleLevel
                      key={key}
                      item={child}
                      expanded={expanded}
                      changeCurrentSub={changeCurrentSub}
                      className={
                        subListCurrent === child.title ? "activeSubList" : ""
                      }
                    />
                  ))}
                </List>
              </Collapse>
            </div>
          ))}

        {!expanded &&
          navigationItems.footer.map((item: any) => (
            <div key={item.id}>
              {item.items === undefined && (
                <IconsOnly
                  key={item.id}
                  item={item}
                  expanded={expanded}
                  changeCurrentSub={changeCurrentSub}
                  current={current}
                  className={
                    subListCurrent === item.title
                      ? "activeIconOnly"
                      : "iconOnlyPadding"
                  }
                />
              )}
                        
              {item.items?.map((child: any, key: any) => (
                <IconsOnly
                  key={key}
                  item={child}
                  // expanded={expanded}
                  changeCurrentSub={changeCurrentSub}
                  current={current}
                  className={
                    subListCurrent === child.title
                      ? "activeIconOnly"
                      : "iconOnlyPadding"
                  }
                />
              ))}
            </div>
          ))}
      </div>
      }

      <SetupGuidePopover
        id={'simple-popover'}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Sidebar;

const SingleLevel = (props: any) => {
  const { item, expanded } = props;

  const navigate = useNavigate();

  const onNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <ListItem button className={props.className}>
      {/* {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>} */}
      {expanded && (
        <ListItemText
          primary={item.title}
          className=""
          onClick={() => {
            props.changeCurrentSub(item.title);
            onNavigate(item.to);
          }}
        />
      )}
    </ListItem>
  );
};

const IconsOnly = (props: any) => {
  const settingInfo = useAppSelector((state: any) => state.reducer.setUpGuide);
  // console.log("setupGuideSteps", settingInfo.setupGuideSteps)
  const { item, expanded, current } = props;
  const navigate = useNavigate();

  const onNavigate = (to: string) => {
    navigate(to);
  };

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const setupGuidePopoverId = 'simple-popover-setupGuide';

  useEffect(() => {
    if (typeof settingInfo.setupGuideSteps !== 'undefined') {
      if (!expanded || Object.keys(settingInfo.setupGuideSteps).length <= 0 || Object.values(settingInfo.setupGuideSteps).includes(false)) {
        setAnchorEl(anchorRef.current);
      } else {
        setAnchorEl(null);
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleClickPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {item.id === 'setupGuide' ? (
        <ListItem
          className={props.className}
          aria-describedby={setupGuidePopoverId}
          onClick={handleClickPopover}
          ref={anchorRef}
        >
          {item.icon && (
            <ListItemIcon
              onClick={() => {
                props.changeCurrentSub(item.title);
                onNavigate(item.to);
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
        </ListItem>
      ) : (
        <ListItem className={props.className}>
          {item.icon && (
            <ListItemIcon
              onClick={() => {
                props.changeCurrentSub(item.title);
                onNavigate(item.to);
              }}
            >
              {item.icon}
              <ListItemText
                sx={{
                  marginTop: '-2px',
                  color: current === item.id ? "#fff" : "#8a98a5",
                }}
              >
                {item.title}
              </ListItemText>
            </ListItemIcon>
          )}
        </ListItem>
      )}

      <SetupGuidePopover
        id={setupGuidePopoverId}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  );
};
