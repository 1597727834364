import { experimental_extendTheme as extendTheme } from "@mui/material";

const lightTheme = extendTheme({
  palette: {
    type: "light",
    primary: {
      main: "#16314c",
    },
    secondary: {
      main: "#f49044",
    },
    backgroundcolor: "#eaecf8",
    colorWhite: "#ffffff",
    colorBlack: "#000000",
    colorBlue: "#588bca",
    background: {
      default: "hsl(0, 0%, 100%)",
    },
  },
});
export default lightTheme;
