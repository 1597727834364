import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse, AxiosError} from "axios";
import AxiosInstance from "../../Network/AxiosInstance";
import {
	ADD_EDIT_ORDER_NOTE,
    GET_ORDERS,
    GET_STORES,
    CREATE_ORDERS,
    GET_STORES_CONNECTORS,
    CREATE_STORES,
    PULL_ORDERS,
    GET_SETTINGS_LIST,
    CALL_FSM_FLOW,
    UPDATE_SETTINGS,
    GET_SHIP_LABEL,
    GET_DELIVERIES,
    GET_CUSTOMER_ORDER_CODE,
    SAVE_ORDERS_ADDRESS,
    GET_ORDERS_STATS,
    VERIFY_NEW_USER_REGISTER,
    GET_ORDER_BOX_SIZES,
    GET_DASHBOARD
} from "../../Utils/Constants";
import {PACKING_SLIP_EVENT, SHIP_LABEL_EVENT, returnObjectToQueryParams} from "../../Utils/Utils";

export const verifyNewUserRegisterApi = createAsyncThunk("Users/verifyNewUserRegister", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${VERIFY_NEW_USER_REGISTER}`;

        const response : AxiosResponse = await AxiosInstance.post(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const orderListApi = createAsyncThunk("Orders/list", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_ORDERS}?size=${
        payload.pagination.pageSize}&page=${payload.pagination.page + 1}&hide=0`;
        if(returnObjectToQueryParams(payload.filter) !== '') {
            url = `${url}&${returnObjectToQueryParams(payload.filter)}`
        }
        url = `${url}&order=id desc`;

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const ordersStatsApi = createAsyncThunk("Orders/Stats", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_ORDERS_STATS}/${payload.period}`;

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const dashboardApi = createAsyncThunk("Dashboard", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_DASHBOARD}/${payload.period}`;

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const getCustomerOrderCodeApi = createAsyncThunk("order/customerOrderCode", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_CUSTOMER_ORDER_CODE}/${payload.storeId}/customerOrderCode`;

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const createOrderApi = createAsyncThunk("order/create", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.post(CREATE_ORDERS + "/1/orders", payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const getOrderApi = createAsyncThunk("order/orderById", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_ORDERS}/${payload.id}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const getDeliveriesApi = createAsyncThunk("shippingMethod/list", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_DELIVERIES}?orderId=${payload.id}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const storeListApi = createAsyncThunk("store/list", async(payload : any, thunkAPI) => {
    try {
        var pageSize = (typeof payload.pageSize !== 'undefined') ? payload.pageSize : '';
        var page = (typeof payload.page !== 'undefined') ? payload.page : 0;

        let url : string = `${GET_STORES}?size=${pageSize}&page=${page + 1}&${returnObjectToQueryParams(payload.filter)}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const storeConnectorsListApi = createAsyncThunk("connectors/list", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.post(GET_STORES_CONNECTORS, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const createOrUpdateStoreApi = createAsyncThunk("store/createOrUpdate", async(payload : any, thunkAPI : any) => {
    try {
        let url: string = CREATE_STORES;
        let response;

        if (payload.id) {
            url = `${url}/${payload.id}`;

            delete payload['id']
            
            response = await AxiosInstance.put(url, payload);
        } else {
            response = await AxiosInstance.post(url, payload);
        }

        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const pullOrdersApi = createAsyncThunk("order/pullOrders", async(payload : any, thunkAPI : any) => {
    try {
        const response : AxiosResponse = await AxiosInstance.get(PULL_ORDERS + "/" + payload.storeId + "/pullOrders");
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const settingsListApi = createAsyncThunk("configs/list", async(_, thunkAPI) => {
    try {
        let url : string = `${GET_SETTINGS_LIST}`;

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const saveSettingsAPI = createAsyncThunk("configs/createOrUpdate", async(payload : any, thunkAPI : any) => {
    try {
        const response = await AxiosInstance.post(UPDATE_SETTINGS, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const getFsmFlowApi = createAsyncThunk("order/fsmFlow", async(payload : any, thunkAPI) => {
    try {
        var fsmFlow:string = ([PACKING_SLIP_EVENT].includes(payload.eventName)) ? '' : '/fsmFlow';
        let url : string = `${CALL_FSM_FLOW}/${payload.orderId}/${payload.eventName}${fsmFlow}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const orderFsmFlowPostApi = createAsyncThunk("order/orderFLow", async(payload : any, thunkAPI : any) => {
    try {
       var orderFlow:string = ([PACKING_SLIP_EVENT].includes(payload.eventName)) ? '' : '/orderFlow';
       let url : string = `${CALL_FSM_FLOW}/${payload.orderId}/${payload.eventName}${orderFlow}`;

       // Remove event key from the payload data
       delete payload['eventName']

        const response = await AxiosInstance.post(url, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const retryTrackingPushApi = createAsyncThunk("orders/retryTrackingPushApi", async(payload : any, thunkAPI : any) => {
    try {
       let url : string = `${GET_SHIP_LABEL}/${payload.deliveryId}/retryTrackingPush`;

        const response = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const saveOrdersAddressApi = createAsyncThunk("order/saveAddressApi", async(payload : any, thunkAPI : any) => {
    try {
        let url : string = `${SAVE_ORDERS_ADDRESS}/${payload.orderId}/${payload.editAddressType}/updateOrderAddress`
        delete payload['orderId'];
        delete payload['editAddressType'];

        const response = await AxiosInstance.post(url, payload);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const getZplLabelCodeApi = createAsyncThunk("order/getZplLabels", async(payload : any, thunkAPI) => {
    try {
        let url : string = '';
        if(payload.eventName === SHIP_LABEL_EVENT) { // This is required, when the label type is not zpl, to proceed regular flow
            url = `${GET_SHIP_LABEL}/${payload.id}/${SHIP_LABEL_EVENT}`;
        }

        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const addOrUpdateOrderNoteApi = createAsyncThunk("orderNote/AddOrUpdate", async(payload : any, thunkAPI : any) => {
console.log(payload);
    try {
    	let response = {data: {}};
    	if (payload.hasOwnProperty('id')) {
    		response = await AxiosInstance.put(ADD_EDIT_ORDER_NOTE + "/" + payload.id, payload);
    	} else {
    		response = await AxiosInstance.post(ADD_EDIT_ORDER_NOTE, payload);
    	}

        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const orderNoteListApi = createAsyncThunk("orderNotes/list", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${ADD_EDIT_ORDER_NOTE}?order_id=${payload.orderId}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

export const getOrderBoxSizesListApi = createAsyncThunk("orders/orderBoxSizes", async(payload : any, thunkAPI) => {
    try {
        let url : string = `${GET_ORDER_BOX_SIZES}`;
        const response : AxiosResponse = await AxiosInstance.get(url);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        return thunkAPI.rejectWithValue({
            status: error.response
                ?.status,
            statusText: error.response
                ?.statusText,
            data: error
        });
    }
});

const initialState = {
    currencyCode: '',
    locale: '',
    token: '',
    cqConnectURL: '',
    labelType: '',
    warehouseInfo: {},
    shipLabelConfig: {},
};

const orderSlice = createSlice({
    name: "ruleSet",
    initialState,
    reducers: {},
    extraReducers(builder) {
        // omit posts loading reducers
        builder.addCase(orderListApi.pending, (state, action : PayloadAction < any >) => {
            // state.status = 'loading' console.log("orderListApi.pending", action)
        }).addCase(orderListApi.fulfilled, (state, action : PayloadAction < any >) => {
            if (action.payload.code === 200 && action.payload.success) {}
            // console.log("orderListApi.fulfilled", action)
        }).addCase(orderListApi.rejected, (state, action) => {
            // console.log("orderListApi.rejected", action)
        }).addCase(settingsListApi.pending, (state, action ) => {
            // state.status = 'loading' console.log("orderListApi.pending", action)
        }).addCase(settingsListApi.fulfilled, (state, action ) => {
            var currencyCode: string = '';
            var locale: string = '';
            var token: string = '';
            var cqConnectURL: string = '';
            var warehouseData: any = {};
            var shipLabelAppConfig: any = {};
            var labelType: string = '';
            const res =  action.payload;
            if (res && res.response && res.response.data !== null && res.response.data !== undefined) {
                action.payload.response.data.forEach((item: any) => {
                    // Update Currency Info
                    if (item.configs.key === 'currency_info' && item.configs.options !== '') {
                        var currencyInfoData = JSON.parse(item.configs.options);
                        currencyCode = currencyInfoData.currencyCode;
                        locale = currencyInfoData.locale;
                    }

                    if (item.configs.key === 'ship_label_app_info' && item.configs.options !== '') {
                        shipLabelAppConfig = JSON.parse(item.configs.options);
                        labelType = shipLabelAppConfig.labelType;

                        if (!shipLabelAppConfig.hasOwnProperty('hashKey')) {
                        	shipLabelAppConfig.hashKey = '';
                        }
                    }

                    if(item.configs.key === 'bearer_token' && item.configs.value !== '') {
                        token = item.configs.value;
                    }

                    if(item.configs.key === 'cq_url' && item.configs.value !== '') {
                        cqConnectURL = item.configs.value;
                    }
                    if (item.configs.key === 'warehouse_info' && item.configs.options !== '') {
                        warehouseData = JSON.parse(item.configs.options);
                    }
                })
            }

            state.currencyCode = currencyCode;
            state.locale = locale;
            state.token = token;
            state.cqConnectURL = cqConnectURL;
            state.warehouseInfo = warehouseData;
            state.labelType = labelType;
            state.shipLabelConfig = shipLabelAppConfig;
        }).addCase(settingsListApi.rejected, (state, action) => {
            // console.log("orderListApi.rejected", action)
        });
    }
});

export default orderSlice.reducer;
