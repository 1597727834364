import { createContext, useContext, useState, ReactNode } from "react";

interface LoaderContextType {
  showLoader: boolean;
  handleLoader: (flag: boolean) => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderContextProvider");
  }
  return context;
};

export const LoaderContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showLoader, setShowLoader] = useState(false);

  const handleLoader = (flag: boolean) => {
    setShowLoader(flag);
  };

  const contextValue: LoaderContextType = {
    showLoader,
    handleLoader,
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
