const Roles: {
  SuperAdmin: number;
  Admin: number;
  Manager: number;
} = {
  SuperAdmin: 1,
  Admin: 2,
  Manager: 3,
};

export default Roles;
