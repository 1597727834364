import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import React from 'react'

interface Props {
  isOpenDeletePopup: boolean;
  handleClose: (value?: boolean, isRefreshListingTable?: boolean) => void;
  handleConfirmDelete: (value?: boolean) => void;
  message: string;
}

const DeletePopup = ({isOpenDeletePopup, handleClose, handleConfirmDelete, message}: Props) => {
  return (
    <div>
      <Dialog
        open={isOpenDeletePopup}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            borderBottom: '1px solid gray',
            marginBottom: '5%'
          }}>Please Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button variant="outlined" onClick={() => handleConfirmDelete(true)} color="warning" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeletePopup
