import { createSlice } from "@reduxjs/toolkit";
// import AxiosInstance from "../../Network/AxiosInstance";
// import { Logout_User_URL } from "../../Utils/Constants";
// import {
//   Change_Password_URL,
//   Forget_Password_URL,
//   Get_User_Info,
//   Login_User_URL,
//   Logout_User_URL,
//   Resend_OTP_URL,
//   Set_Password_URL,
//   Update_Profile_URL,
//   Verify_OTP_URL,
// } from "../../Utils/Constants";

// export const LoginUser = createAsyncThunk("userData/login", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.post(Login_User_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const updateProfileApi = createAsyncThunk("userData/update", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.patch(Update_Profile_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const changePasswordApi = createAsyncThunk("userData/changepassword", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.post(Change_Password_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const logoutUserApi = createAsyncThunk("userData/logout", async (_, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.get(Logout_User_URL);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const ForgetPasswordApi = createAsyncThunk("userData/forgotpassword", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.post(Forget_Password_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const setPasswordApi = createAsyncThunk("userData/setPassword", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.post(Set_Password_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const verifyOTPApi = createAsyncThunk("userData/verifyOTP", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.post(Verify_OTP_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const resendOTPApi = createAsyncThunk("userData/resendOTP", async (payload, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.post(Resend_OTP_URL, payload);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

// export const getUserInfo = createAsyncThunk("userData/userInfo", async (_, thunkAPI) => {
//   try {
//     const response = await AxiosInstance.get(Get_User_Info);
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ status: error.status, statusText: error.statusText });
//   }
// });

const initialState = {
  ID: "",
  client_id: "",
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  country: "US",
  state: "",
  zipcode: "",
  user_role_id: 2,
  status: "",
};
//current user
const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUserData: (state, { payload }) => {
      // console.log(payload);
      // state.ID = payload.data.ID;
      // state.client_id = payload.data.client_id;
      // state.email = payload.data.email;
      // state.first_name = payload.data.first_name;
      // state.last_name = payload.data.last_name;
      // state.phone = payload.data.phone;
      // state.address1 = payload.data.address1;
      // state.address2 = payload.data.address2;
      // state.city = payload.data.city;
      // state.country = payload.data.country;
      // state.state = payload.data.state;
      // state.zipcode = payload.data.zipcode;
      // state.user_role_id = payload.data.user_role_id;
      // state.status = payload.data.status;
    },
    updateProfileDetails: (state, action) => {
      //   console.log(action.payload);
      // state[action.payload.name] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // omit posts loading reducers
    // builder;
    //   .addCase(LoginUser.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("login.pending", action)
    //   })
    //   .addCase(LoginUser.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //       let finalData = action.payload.data;
    //       state.ID = finalData.ID;
    //       state.client_id = finalData.client_id;
    //       state.email = finalData.email;
    //       state.first_name = finalData.first_name;
    //       state.last_name = finalData.last_name;
    //       state.phone = finalData.phone;
    //       state.address1 = finalData.address1;
    //       state.address2 = finalData.address2;
    //       state.city = finalData.city;
    //       state.country = finalData.country;
    //       state.state = finalData.state;
    //       state.zipcode = finalData.zipcode;
    //       state.user_role_id = finalData.user_role_id;
    //       state.status = finalData.status;
    //     }
    //     // console.log("login.fulfilled", action)
    //   })
    //   .addCase(LoginUser.rejected, (state, action) => {
    //     // console.log("login.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    //   .addCase(updateProfileApi.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("updateProfileApi.pending", action)
    //   })
    //   .addCase(updateProfileApi.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //     }
    //     // console.log("updateProfileApi.fulfilled", action)
    //   })
    //   .addCase(updateProfileApi.rejected, (state, action) => {
    //     // console.log("updateProfileApi.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    //   .addCase(changePasswordApi.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("changePasswordApi.pending", action)
    //   })
    //   .addCase(changePasswordApi.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //     }
    //     // console.log("changePasswordApi.fulfilled", action)
    //   })
    //   .addCase(changePasswordApi.rejected, (state, action) => {
    //     // console.log("changePasswordApi.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    // .addCase(logoutUserApi.pending, (state, action) => {
    //   // state.status = 'loading'
    //   // console.log("logoutUserApi.pending", action)
    // })
    // .addCase(logoutUserApi.fulfilled, (state, action) => {
    //   if (action.payload.code === 200 && action.payload.success) {
    //   }
    //   // console.log("logoutUserApi.fulfilled", action)
    // })
    // .addCase(logoutUserApi.rejected, (state, action) => {
    //   // console.log("logoutUserApi.rejected", action)
    //   // state.status = 'failed'
    //   // state.error = action.error.message
    // });
    //   .addCase(ForgetPasswordApi.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("ForgetPasswordApi.pending", action)
    //   })
    //   .addCase(ForgetPasswordApi.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //     }
    //     // console.log("ForgetPasswordApi.fulfilled", action)
    //   })
    //   .addCase(ForgetPasswordApi.rejected, (state, action) => {
    //     // console.log("ForgetPasswordApi.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    //   .addCase(setPasswordApi.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("setPasswordApi.pending", action)
    //   })
    //   .addCase(setPasswordApi.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //     }
    //     // console.log("setPasswordApi.fulfilled", action)
    //   })
    //   .addCase(setPasswordApi.rejected, (state, action) => {
    //     // console.log("setPasswordApi.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    //   .addCase(verifyOTPApi.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("verifyOTPApi.pending", action)
    //   })
    //   .addCase(verifyOTPApi.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //     }
    //     // console.log("verifyOTPApi.fulfilled", action)
    //   })
    //   .addCase(verifyOTPApi.rejected, (state, action) => {
    //     // console.log("verifyOTPApi.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    //   .addCase(resendOTPApi.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("resendOTPApi.pending", action)
    //   })
    //   .addCase(resendOTPApi.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //     }
    //     // console.log("resendOTPApi.fulfilled", action)
    //   })
    //   .addCase(resendOTPApi.rejected, (state, action) => {
    //     // console.log("resendOTPApi.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   })
    //   .addCase(getUserInfo.pending, (state, action) => {
    //     // state.status = 'loading'
    //     // console.log("getUserInfo.pending", action)
    //   })
    //   .addCase(getUserInfo.fulfilled, (state, action) => {
    //     if (action.payload.code === 200 && action.payload.success) {
    //       let finalData = action.payload.data;
    //       state.ID = finalData.ID;
    //       state.client_id = finalData.client_id;
    //       state.email = finalData.email;
    //       state.first_name = finalData.first_name;
    //       state.last_name = finalData.last_name;
    //       state.phone = finalData.phone;
    //       state.address1 = finalData.address1;
    //       state.address2 = finalData.address2;
    //       state.city = finalData.city;
    //       state.country = finalData.country;
    //       state.state = finalData.state;
    //       state.zipcode = finalData.zipcode;
    //       state.user_role_id = finalData.user_role_id;
    //       state.status = finalData.status;
    //     }
    //     // console.log("getUserInfo.fulfilled", action)
    //   })
    //   .addCase(getUserInfo.rejected, (state, action) => {
    //     // console.log("getUserInfo.rejected", action)
    //     // state.status = 'failed'
    //     // state.error = action.error.message
    //   });
  },
});

export const { updateUserData, updateProfileDetails } = userDataSlice.actions;

export default userDataSlice.reducer;
