import React from "react";

function BlankPage() {
  return (
    <div className="">
      <div className="mainDiv">
        <div className="titleContainer">
          <div className="left"></div>
          <div className="right"></div>
        </div>

        <div className=""></div>
      </div>
    </div>
  );
}

export default BlankPage;
