import { toast } from "react-toastify";
import { customError } from "../Models/Types";
import { ValidationRules } from "../Models/Validation";
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const valA =
    typeof a[orderBy] === "string"
      ? (a[orderBy] as unknown as string).toLowerCase()
      : a[orderBy];
  const valB =
    typeof b[orderBy] === "string"
      ? (b[orderBy] as unknown as string).toLowerCase()
      : b[orderBy];

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(order: "asc" | "desc", orderBy: keyof T) {
  return order === "desc"
    ? (a: T, b: T) => descendingComparator<T>(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator<T>(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function checkForBlankFields(
  obj: Record<any, any>
): Record<any, boolean> {
  const errorFields: Record<any, boolean> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && key !== "error") {
      const value = obj[key];
      if (value === undefined || value === null || value === "") {
        errorFields[key] = true;
      } else {
        errorFields[key] = false;
      }
    }
  }

  return errorFields;
}

export function checkForBlankFieldsWithRules(
  obj: Record<string, any>,
  validationRules: ValidationRules
): Record<string, boolean> {
  const errorFields: Record<string, boolean> = {};

  for (const key in validationRules) {
    if (Object.prototype.hasOwnProperty.call(validationRules, key)) {
      const rule = validationRules[key];
      const value = obj[key];
      const isBlank = value === undefined || value === null || value === "";
      const isPatternMismatch =
        rule.pattern && typeof value === "string" && !rule.pattern.test(value);
      const isMinLengthMismatch =
        rule.minLength !== undefined &&
        typeof value === "string" &&
        value.length < rule.minLength;
      const isMaxLengthMismatch =
        rule.maxLength !== undefined &&
        typeof value === "string" &&
        value.length > rule.maxLength;
      const isCustomMismatch = rule.custom !== undefined && !rule.custom(value);

      errorFields[key] =
        (rule.required && isBlank) ||
        isPatternMismatch ||
        isMinLengthMismatch ||
        isMaxLengthMismatch ||
        isCustomMismatch;
    }
  }

  return errorFields;
}

export const formatCurrency = (value: number, currency: string, locale: string): string => {

  if(currency === '') {
    currency = DEFAULT_CURRENCY_CODE;
  }

  if(locale === '') {
    locale = DEFAULT_LOCALE;
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

export const returnObjectToQueryParams = (payload: any) => {
  const searchParams = new URLSearchParams();
  for (const key in payload) {
    if (payload[key])
      searchParams.append(
        key,
        ["created", "created_at", "orderDate"].includes(key) ? `eq@${payload[key]}` : payload[key]
      );
  }

  // Get the query parameters string
  const queryString = searchParams.toString();
  return queryString;
};

export const returnCountOfFilterApply = (payload: any) => {
  let count = 0;

  // Iterate over the keys in the object
  for (const key in payload) {
    if (payload.hasOwnProperty(key) && payload[key] !== "" && payload[key].length !== 0) {
      count++;
    }
  }

  return count;
};

export function firstLetterUpperCase(str: string | undefined) {
  if ((str !== "") && (str !== undefined)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return "";
}

export function setStorageData (key: string, dataList: any) {
	return localStorage.setItem(key, JSON.stringify(dataList));
}

export function getStorageData (key: string) {
	return (localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)!) : null;
}

export function getTodaysDate () {
  const dateObj = new Date();
  return dateObj.toISOString().split('T')[0];
}

export function getFormattedDate (date: string){
  if (typeof date !== 'undefined' && date !== null) {
    var dateArr = date.split('T');
    return dateArr[0] + ' ' + dateArr[1].split('Z')[0];
  }

  return date;
}

export function handleErrorsAndDisplay(err: any, defaultErrorMessage: string) {
  const error = err as customError;
  console.log('error', error)
  
  if(error?.data?.data?.response?.errors !== null && typeof error.data?.data?.response?.errors !== 'undefined') {
    var errorsList = Object.values(error?.data?.data?.response?.errors);
    toast.error(errorsList.join(', '));
  } else if(error?.statusText !== null && typeof error.statusText !== 'undefined') {
    toast.error(error.statusText);
  }
  else {
    toast.error(defaultErrorMessage);
  }
}

// Order addresses types
export const ORDER_ADDRESS_TYPE_BILLING: string = "billing";
export const ORDER_ADDRESS_TYPE_SHIPPING: string = "shipping";

export const DEFAULT_PAGESIZE: number = 10;

// Order Status List
export const ORDER_DRAFT_ORDER: string = "Draft Order";
export const ORDER_SALES_ORDER: string = "Sales Order"
export const ORDER_COMPLETED: string = "Completed"
export const ORDER_CANCELED: string = "Canceled"
export const ORDER_DELETED: string = "Deleted"
export const ORDER_COMPLETED_CANCELED_PARTIAL = "Completed - Canceled Partial"

// Payment Status List
export const PAYMENT_PAID: string = "Paid"
export const PAYMENT_UN_PAID: string = "Unpaid"
export const PAYMENT_TERMS: string = "Terms"
export const PAYMENT_PAID_PARTIAL: string = "Paid Partial"

// Shipment Status List
export const SHIPMENT_UN_SHIPPED: string = "Unshipped"
export const SHIPMENT_PARTIAL: string = "Shipped Partial"
export const SHIPMENT_COMPLETED: string = "Shipped Complete"

// FSM Events list
export const PACKING_SLIP_EVENT: string = 'packingSlip';
export const CANCEL_EVENT: string = 'cancel';
export const APPROVE_EVENT: string = 'approve';
export const SHIP_COMPLETE_EVENT: string = 'shipComplete';
export const PROCESS_PAYMENTS_EVENT: string = 'processPayment';

export const SHIP_LABEL_EVENT: string = 'shipLabel';
export const LABEL_TYPE_ZPL: string = 'zpl';

// Guided tour setup steps
export const SHIPPING_METHOD_STEP:string = 'shippingMethods';
export const CARRIER_MAPPING_STEP:string = 'shippingMethodCarrierMapping';
export const STORE_MAPPING_STEP:string = 'shippingMethodStoreMapping';
export const SHIPPING_API_STEP:string = 'shippingApi';
export const STORES_STEP:string = 'store';

export const GUIDED_STEPS_LIST = [SHIPPING_METHOD_STEP, CARRIER_MAPPING_STEP, STORE_MAPPING_STEP, SHIPPING_API_STEP, STORES_STEP]

export const DEFAULT_CURRENCY_CODE  = 'USD';
export const DEFAULT_LOCALE  = 'en';

// Settings Tabs Unique IDs
export const SHIPPING_LABEL_APP_TAB_ID = 0;
export const WAREHOUSE_INFO_TAB_ID = 1;
export const SHIPPING_METHOD_TAB_ID = 2;
export const STORE_MAPPING_TAB_ID = 3;
export const STORE_METHOD_CARRIER_MAPPING_TAB_ID = 4;
export const OTHER_SETTINGS_TAB_ID = 5;
export const CURRENCY_INFO_TAB_ID = 6;
export const DEVICES_AND_PRINTERS_TAB_ID = 7;

// Settings Menu Devices and menu Tabs keys set
export const PRINTER_KEY = 'defaultPrinter';
export const SCALES_KEY = 'defaultScale';

export const noteTypes = [
	'Internal',
	'Customer',
	'Shipping',
	'Gift Message',
];

export const severityOptions = [
	'General',
	'Critical',
	'Major',
	'Minor',
	'Trivial',
];

export const fShortenNumber = (number: number) => {
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + "K";
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + "M";
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + "B";
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
};

export const convertFileToBase64 = async(file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}

export const DEFAULT_USER_TIMEZONE: string = 'America/New_York';

// Set store type constants
export const STORE_TYPE_WHOLESALE: string = "Wholesale";
export const STORE_TYPE_EBAY: string = "Ebay";
export const STORE_TYPE_AMAZON_SP: string = "AmazonSP";
export const STORE_TYPE_BIG_COMMERCE: string = "Bigcommerce";
export const STORE_TYPE_WALMART: string = "Walmart";
export const STORE_TYPE_SHOPIFY: string = "Shopify";
export const STORE_TYPE_MAGENTO: string = "Magento";
