import React from "react";
import Typography from "@mui/material/Typography";
import PaidConnections from "../Components/AddOns/PaidConnections";
import { Alert, Stack } from "@mui/material";

const AddOns: React.FC = () => {
  return (
    <div className="">
      <div className="mainDiv">
        <div className="titleContainer">
          <div className="left">
            <div className="pageTitle">
              <Typography variant="h5">Add-Ons</Typography>
            </div>
          </div>
          <div className="right">
            <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="info" variant="outlined">
                Coming Soon...
                </Alert>
            </Stack>
          </div>
        </div>

        <div>
          <PaidConnections />
        </div>
      </div>
    </div>
  );
};

export default AddOns;
