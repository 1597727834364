import React from 'react'

interface Props {
  list : any;
  startNumber? : number;
}

const ListingComponent = ({list, startNumber = 1}: Props) => {
  return (
    <ol start={startNumber}>
      {list?.map((item: any, index:any) => {
        return <li key={index}>{item}</li>
      })}
    </ol>
  )
}

export default ListingComponent
