export const Logout_User_URL: string = "/api/user/logout";

export const Rule_Set_List_URL: string = "/api/ruleset/get";

export const GET_ORDERS: string = "/api/v1/orders";

export const GET_ORDERS_STATS: string = "/call/quickDashboard";

export const GET_DASHBOARD: string = "/call/dashboard";

export const GET_CUSTOMER_ORDER_CODE: string = "/call/store";

export const GET_DELIVERIES: string = "/api/v1/deliveries";

export const GET_SHIPPING_METHOD: string = "/api/v1/shipMethods";

export const GET_SHIPPING_CARRIER_METHOD: string = "/api/v1/shipMethodCarrierMethodMappings";

export const GET_STORES: string = "/api/v1/stores";

export const CREATE_ORDERS: string = "/api/v1/store";

export const GET_STORES_CONNECTORS: string = "/api/v1/cqConnect/stores";

export const CREATE_STORES: string = "/api/v1/stores";

export const PULL_ORDERS: string = "/call/store";

export const GET_SHIPPING_METHOD_STORE_MAPPINGS: string = "/api/v1/shipMethodStoreMappings";

export const GET_SETTINGS_LIST: string = "/api/v1/configs";

export const CALL_FSM_FLOW: string = "/call/orders";

export const UPDATE_SETTINGS: string = "/call/configs/createOrUpdate";

export const GET_SHIP_LABEL: string = "/call/shipment";

export const GET_SETUP_GUIDE_STEPS: string = "/call/setupGuide";

export const SAVE_ORDERS_ADDRESS: string = "/call/order";

export const VERIFY_NEW_USER_REGISTER: string = "/call/verifyNewUserRegister";

export const ADD_EDIT_ORDER_NOTE: string = "/api/v1/orderNotes";

export const GET_ORDER_BOX_SIZES: string = "/api/v1/orderBoxSizes";