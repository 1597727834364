import React from 'react';
import "../Assets/Css/bc_redirect.css";
import {Alert, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@mui/material";
import clsx from "clsx";
import SwpLogo from '../Assets/swpLogoSmall.png';
import { useLocation } from "react-router-dom";
import ListingComponent from '../Components/ResusableComponents/ListingComponent';

const BCRedirectAndInstructionsGuide : React.FC = () => {
	const location = useLocation();

	const redirectToLogin = () => {
		let redirectUrl: any = process.env.REACT_APP_BASE_URL;

		window.open(redirectUrl, '_blank');
	}

	const BCInstructions = [
		'Locate the "SalesWarp Ship" application.',
		'Click Install. The app will get installed and you will be redirected to our authentication page',
		'Click the Register button and enter an email address to create a new account',
		'You will receive a one-time password sent to the email address. Enter the OTP for verification',
		'Complete the registration process by entering your Name, unique company name, and a password and click Signup',
        'You will be redirected to the login page on successful registration',
        'Login with your registered credentials and you will be redirected to the product listing page. There you will see the product "SalesWarp Ship"',
        'Click on view plans button to take you to the subscription page',
        'Click on the subscribe button under the Free plan',
		"Then you'll be redirected to the SalesWarp Ship application store listing page where you can see that your BigCommerce store has been connected"
    ];

	return (
		<div className={clsx("outerContainer", "width100")}>
			<div className="loginInnerContainer">
				<Grid container spacing={0}>
					<div className="App-header">
						<img src={SwpLogo}  className="AppLogo" alt="logo" />
						
						{/* BC Store redirec code */}
						{['bc_redirect'].indexOf(location.pathname.replaceAll('/', '')) !== -1 && (
							<>
							<div className="swpDesc">
								<Alert variant="outlined" severity="success"> App has been successfully registered.</Alert>
							</div>

							<div className="homeLinks">
								<div className="AppTxt">
									<button className="App-link" onClick={() => redirectToLogin()}>Go to SalesWarp Ship Login Page</button>
								</div>
							</div>
							</>
						)}	

						{/* BC Store setup instructions  guide */}
						{['bc_instruction_guide'].indexOf(location.pathname.replaceAll('/', '')) !== -1 && (
							<>
								 <Card>
								<CardHeader  
									subheader={<Typography>Setup Instructions</Typography>} 
									style={{ 
										fontSize: '15px !important', 
										backgroundColor: '#80808026',
										padding:'10px',
										color: 'black'
									}}
								/>
								<Divider />
								<CardContent style={{padding:0, fontSize: 15}}>
									<Typography variant='subtitle2' pt={2} pl={2}>App Name: SalesWarp Ship</Typography>
									<ListingComponent list={BCInstructions} />
								</CardContent>
							</Card>
							</>
						)}
					</div>
				</Grid>
			</div>
		</div>
	);
};

export default BCRedirectAndInstructionsGuide;
