import { AppBar, Button, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import ConfirmPopup from "./ResusableComponents/DeletePopup";
import clsx from "clsx";
import userIcon from "../Assets/user-ic.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { firstLetterUpperCase } from "../Utils/Utils";
import { Stack } from "@mui/system";

function HeaderComp(props: any) {
  const navigate = useNavigate();
  const { logout, user, isAuthenticated } = useAuth0();
  const [isOpenConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleLogout = (value?: boolean) => {
    setOpenConfirmPopup(true);
    if (value) {
      setOpenConfirmPopup(false);
      localStorage.clear();
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (to: string | null) => {
    setAnchorEl(null);
    if (to !== null) {
      navigate(to);
    }
  };

  const handleConfirmClose = (value?: boolean, isRefreshListingTable?: boolean) => {
      setOpenConfirmPopup(false);
  };

  useEffect(() => {
    return () => {
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AppBar position="static" className="AppBar">
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{flexGrow: 1}}
        ></Typography>

        {props.children}

        <ConfirmPopup
          isOpenDeletePopup={isOpenConfirmPopup}
          handleClose={handleConfirmClose}
          handleConfirmDelete={handleLogout}
          message="Are you sure you want to log out?"
        />

        {isAuthenticated && (
          <div>
            <Button
              id="demo-positioned-button"
              className={clsx("btnFontSize", "fontWeight")}
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              startIcon={<img alt="" src={userIcon} className="AppBarUserIcon" />}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {firstLetterUpperCase(user?.name || "")}
            </Button>
            <Menu
              className="headerMenu"
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(null)}
            >
              <MenuItem className="displayBlock">
                <a href={process.env.REACT_APP_AML_BASE_URL +'profile'} rel="noopener noreferrer"  target="_blank" style={{
                  textDecoration: 'none',
                  color: '#fff'
                }}>
                  <Stack direction="row" alignItems="center">
                    <ManageAccountsIcon fontSize="medium" className="logoutIcon" />
                    <span>View Profile</span>
                  </Stack>
                </a>
              </MenuItem>
              <MenuItem onClick={() => handleLogout(false)} className="displayBlock">
                <LogoutIcon fontSize="medium" className="logoutIcon" />
                <span>Logout</span>
              </MenuItem>
            </Menu>
          </div>
        )}

      </Toolbar>
    </AppBar>
  );
}

export default HeaderComp;
