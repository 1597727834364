import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import themeSlice from "./Slice/themeSlice";
import settingSlice from "./Slice/settingSlice";
import userDataSlice from "./Slice/userDataSlice";
import orderSlice from "./Slice/orderSlice";
import shippingMethodSlice from "./Slice/shippingMethodSlice";
import setupGuideSlice from "./Slice/setupGuideSlice";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const rootReducer = combineReducers({
  setting: settingSlice,
  userData: userDataSlice,
  orders: orderSlice,
  setUpGuide: setupGuideSlice
});
// below combine reducers will be persisted
const persistedReducer = persistReducer(persistConfig, rootReducer);

const AllReducers = combineReducers({
  reducer: persistedReducer,
  theme: themeSlice,
  orders: orderSlice,
  shippingMethods: shippingMethodSlice,
});

const resettableRootReducer = (
  state: any,
  action: { type: string; payload: object }
) => {
  // console.log("Action",action)
  if (action.type === "RESET") {
    return AllReducers(undefined, action);
  }
  return AllReducers(state, action);
};

const store: any = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
