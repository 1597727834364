import {Typography} from "@mui/material";
import clsx from "clsx";
import {
  Link, 
  // useNavigate
} from "react-router-dom";

function FooterComp(): JSX.Element {
  // const navigate = useNavigate();
  return (
    <footer className="FooterBar">
      <Typography
        variant="h6"
        className={clsx("VersionInfo", "cursorPointer")}
        // onClick={() => navigate("/Version")}
      >
        Version: {process.env.REACT_APP_SW_SHIP_APP_VERSION}
      </Typography>
      <Typography variant="h6" className="Copyright">
        {"Copyright © "}
        <Link target="_blank" to="https://www.saleswarp.com/">SalesWarp</Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </footer>
  );
}

export default FooterComp;
