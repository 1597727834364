import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  CardHeader,
  CardActions,
  Stack,
  Chip,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { AddOns } from "../../Models/AddOns";
import { formatCurrency } from "../../Utils/Utils";

interface Props {
  addon: AddOns;
}

const PricingCard = ({ addon }: Props) => {
  const [billingCycle, setBillingCycle] = useState("monthly");

  const handleBillingCycleChange = (cycle: string) => {
    setBillingCycle(cycle);
  };

  const Title = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={9}>
          <Typography variant="h6" component="div">
            {addon.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {billingCycle === "monthly" && (
                <Chip
                  label={
                    <Typography variant="subtitle2">
                      {formatCurrency(addon.monthlyPrice, "", "") + "/M"}
                    </Typography>
                  }
                />
              )}
              {billingCycle === "yearly" && (
                <Chip
                  label={
                    <Typography variant="subtitle2">
                      {formatCurrency(addon.yearlyPrice, "", "") + "/Y"}
                    </Typography>
                  }
                />
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    );
  };
  return (
    <Card>
      <CardHeader
        title={<Title />}
        sx={{ backgroundColor: "#eceff7" }}
      ></CardHeader>
      <Divider />

      <CardContent sx={{ height: 150, overflowY: "auto" }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Typography variant="body2" component="div">
              {addon.content}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />
      <CardActions>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12} md={7}>
            <ToggleButtonGroup value={billingCycle} exclusive size="small">
              <ToggleButton
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#4caf50",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#81c784",
                  },
                }}
                value="monthly"
                onClick={() => handleBillingCycleChange("monthly")}
              >
                Monthly
              </ToggleButton>

              <ToggleButton
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#4caf50",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#81c784",
                  },
                }}
                value="yearly"
                onClick={() => handleBillingCycleChange("yearly")}
              >
                Yearly
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item xs={12} sm={12} md={5} justifyContent={'left'}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<GetAppIcon />}
              disabled
            >
              install
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

const PaidConnections = () => {
  const [addonsList, setAddonsList] = useState<AddOns[]>([
    {
      id: "shipping_rules_engine",
      title: "Shipping Rules Engine",
      monthlyPrice: 1,
      yearlyPrice: 500,
      content:
        "To do rules around their shipping and to generate, determine which carrier or which service to use.",
    },
    {
      id: "inventory_management",
      title: "Inventory Management",
      monthlyPrice: 1,
      yearlyPrice: 800,
      content:
        "if they can turn on inventory management then they can purchase that as an additional feature That would then be connect to an inventory service and then just display your inventory and update your inventory and everything. ",
    },
    {
      id: "product_management",
      title: "Product Management",
      monthlyPrice: 1,
      yearlyPrice: 1000,
      content:
        "To manage products, track inventory, and automate inventory adjustments.",
    },
    {
      id: "customer_management",
      title: "Customer Management",
      monthlyPrice: 1,
      yearlyPrice: 1200,
      content:
        "To manage customers, track their purchases, and automate customer relationship management.",
    },
  ]);

  return (
    <Grid container spacing={2} justifyContent="left">
      {addonsList.map((addon: any) => (
        <Grid item xs={12} sm={6} md={4} key={addon.id}>
          <PricingCard addon={addon} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PaidConnections;
