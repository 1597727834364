import React, { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import FooterComp from "./Components/FooterComp";
import lightTheme from "./Theme/lightTheme";
import darkTheme from "./Theme/darkTheme";
import Sidebar from "./Layouts/Sidebar";
import HeaderComp from "./Components/HeaderComp";
import QuickAccess from "./Components/QuickAccess";
import { LoaderContextProvider } from "./Context/LoaderContext";
import MainRoutes from "./Routes/MainRoutes";
import { settingsListApi } from "./Redux/Slice/orderSlice";
import { useAppDispatch, useAppSelector } from "./Redux/Hooks/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getStorageData, setStorageData } from "./Utils/Utils";
import "react-toastify/dist/ReactToastify.css";
import LoaderComponent from "./Components/LoaderComponent";
import { setupGuideStepsApi } from "./Redux/Slice/setupGuideSlice";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedTheme = useAppSelector((state: any) => state.theme);
  const location = useLocation();
  const [isEmptyLayout, setIsEmptyLayout] = useState(false);

  const expanded = useAppSelector(
    (state: any) => state.reducer.setting.isMenuExpanded
  );

  const authData = getStorageData("authData");
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const settingsTabSelected = getStorageData("settingsTabSelected");
  useEffect(() => {
    if ((location.pathname !== '/settings') && (settingsTabSelected !== null)) {
      localStorage.removeItem('settingsTabSelected');
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated && (authData === null)) {
      const getToken = async () => {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setStorageData("authData", {
            token: accessToken,
            lastActivityAt: Math.round(Date.now() / 60000),
          });

          dispatch(settingsListApi());
          dispatch(setupGuideStepsApi());
        }
      };

      getToken();
    }

    return () => {}; // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
  	setIsEmptyLayout(['register', 'bc_redirect', 'bc_instruction_guide'].indexOf(location.pathname.replaceAll('/', '')) !== -1);

    let timeNow = Math.round(Date.now() / 60000);
    if (
      authData &&
      timeNow - authData.lastActivityAt >
        parseInt(process.env.REACT_APP_LOGIN_TIMEOUT! ?? 60)
    ) {
      localStorage.clear();
      logout({ logoutParams: { returnTo: window.location.origin } });

      return;
    }

    // update last activity time
    if (authData) {
      authData.lastActivityAt = timeNow;
      setStorageData("authData", authData);
    } // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <LoaderContextProvider>
        <CssVarsProvider
          theme={selectedTheme.darkMode ? darkTheme : lightTheme}
        >
          <CssBaseline />
           { isEmptyLayout
				?
	          	 	<div className="appDisplay">
	          	 		<MainRoutes />
	          	 	</div>
				:
		        	<div className="appDisplay">
			            <Sidebar />
			            <div className={expanded ? "rootContainerExpand" : "rootContainer"}>
			              <HeaderComp />
			              <MainRoutes />
			              <FooterComp />
			              <QuickAccess />
			            </div>
			        </div>
	        }
        </CssVarsProvider>
        <LoaderComponent />
      </LoaderContextProvider>
    </div>
  );
};

export default App;
