import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import DashboardGrid from "../Components/Dashboard/DashboardGrid";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useLoader } from "../Context/LoaderContext";
import { dashboardApi } from "../Redux/Slice/orderSlice";
import { useDispatch } from "react-redux";
import { handleErrorsAndDisplay } from "../Utils/Utils";
import { dashboardResType, salesByChannelType } from "../Models/Dashboard";
// TODO remove, this shouldn't need to reset the theme.

const Dashboard = () => {
  const { handleLoader } = useLoader();
  const dispatch = useDispatch();
  const periodsList = ["Today", "This Week", "This Month"];
  const [periodType, setPeriodType] = useState("This Week");

  const [salesCount, setSalesCount] = useState<dashboardResType[]>([]); // Widgets data
  const [salesByDate, setSalesByDate] = useState<dashboardResType[]>([]); // Bar graph data
  const [salesByChannel, setSalesByChannel] = useState<salesByChannelType[]>([]); // Pie chart data

  const handleChange = (event: React.MouseEvent<HTMLElement>, selectedPeriodType: string) => {
    if (selectedPeriodType !== "" && selectedPeriodType !== null) {
      setPeriodType(selectedPeriodType);
    }
  };

  useEffect(() => {
    getDashboard(dashboardApi, { period: periodType });
    // eslint-disable-next-line
  }, [periodType]);

  const getDashboard = async (ordersStatsApi: any, payload: any): Promise<void> => {
    handleLoader(true);

    try {
      const res = await dispatch(ordersStatsApi(payload)).unwrap();

      if (!res.error && res.response !== null && res.response !== undefined) {
        // salesCount state update
        if (
          res.response.salesCount !== "" &&
          res.response.salesCount !== null &&
          res.response.salesCount !== "null"
        ) {
          setSalesCount(JSON.parse(res.response.salesCount));
        } else {
          setSalesCount([]);
        }

        // salesByDate state update
        if (
          res.response.salesByDate !== "" &&
          res.response.salesByDate !== null &&
          res.response.salesByDate !== "null"
        ) {
          setSalesByDate(JSON.parse(res.response.salesByDate));
        } else {
          setSalesByDate([]);
        }

        // salesByChannel state update
        if (
          res.response.salesByChannel !== "" &&
          res.response.salesByChannel !== null &&
          res.response.salesByChannel !== "null"
        ) {
          setSalesByChannel(JSON.parse(res.response.salesByChannel));
        } else {
          setSalesByChannel([]);
        }

        handleLoader(false);
      }
    } catch (error) {
      handleErrorsAndDisplay(error, "Failed to get dashboard statistics.");
      setSalesCount([]);
      setSalesByDate([]);
      setSalesByChannel([]);
      handleLoader(false);
    }
  };

  return (
    <div className="">
      <div className="mainDiv">
        <div className="titleContainer">
          <div className="left">
            <div className="pageTitle">
              <Typography variant="h5">Dashboard</Typography>
            </div>
          </div>
          <div
            className="right"
            style={{ backgroundColor: "#fff", borderRadius: 5 }}
          >
            <ToggleButtonGroup
              color="primary"
              value={periodType}
              exclusive
              onChange={handleChange}
              aria-label="periodType"
            >
              {periodsList.map((period) => (
                <ToggleButton value={period} key={period}>
                  {period}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </div>

        <div>
          <DashboardGrid
            periodType={periodType}
            salesCount={salesCount}
            salesByDate={salesByDate}
            salesByChannel={salesByChannel}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
