import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isAuthenticated: false,
  isMenuExpanded: false,
};

const settingSlice: any = createSlice({
  name: "setting",
  initialState,
  reducers: {
    updateAuth: (state, { payload }) => {
      // console.log(payload);
      state.isAuthenticated = payload;
    },
    toggleMenu: (state, action) => {
      state.isMenuExpanded = !state.isMenuExpanded;
    },
    toggleMenuOff: (state, action) => {
      state.isMenuExpanded = false;
    },
  },
//   extraReducers(builder) {
//     // omit posts loading reducers
//     builder.addCase(setupGuideStepsApi.pending, (state, action : PayloadAction < any >) => {
//         // state.status = 'loading' console.log("shippingMethodListApi.pending", action)
//     }).addCase(setupGuideStepsApi.fulfilled, (state, action : PayloadAction < any >) => {
//       if (action.payload.code === 200 && action.payload.success) { }
//       // console.log("shippingMethodListApi.fulfilled", action)
//       const res = action.payload;
//       if (res && res.response !== null && res.response !== undefined) {
//         state.setupGuideSteps = res.response;
//         state.isSetupApiDone  = true;
//       }
//     }).addCase(setupGuideStepsApi.rejected, (state, action) => {
//         // console.log("shippingMethodListApi.rejected", action)
//     });
// }
});

export const { updateAuth, toggleMenu, toggleMenuOff } = settingSlice.actions;

export default settingSlice.reducer;
